import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Box, Stack, Cluster } from "react-every-layout";
import Bordered from "./Bordered";
import { graphql, useStaticQuery } from "gatsby";
import pinstripeLogo from "../img/pinstripe-horizontal.svg";

const ADD_GUESTBOOK_ENTRY = gql`
  mutation CreateGuestbookEntry($author: String!, $text: String!) {
    createGuestbookEntry(data: { author: $author, text: $text }) {
      _id
    }
  }
`;

const GuestbookForm = () => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [addGuestbookEntry, { data }] = useMutation(ADD_GUESTBOOK_ENTRY);
  return data ? (
    console.log("Data", data) || <h2>Danke für Ihren Eintrag</h2>
  ) : (
    <Bordered>
      <div className="index-logo-container">
        <form
          id="guestbook-form"
          onSubmit={(event) => {
            event.preventDefault();
            console.log("Gobibobi")
            addGuestbookEntry({ variables: { author: name, text } });
          }}
        >
          <Stack>
            <h2>Hinterlassen Sie uns doch eine Nachricht</h2>
            <Cluster align="baseline">
              <div>
                <label form="guestbook-form" htmlFor="guestbook-name-input">
                  <h4>Ihr Name:</h4>
                </label>
                <input
                  id="guestbook-name-input"
                  value={name}
                  className="large-input"
                  onChange={(x) => x && setName(x.target.value)}
                ></input>
              </div>
            </Cluster>
            <div>
              <label htmlFor="guestbook-text-input">
                <h4>Ihr Eintrag:</h4>
              </label>
              <textarea
                id="guestbook-text-input"
                value={text}
                style={{ width: "100%" }}
                rows="14"
                columns="80"
                onChange={(x) => x && setText(x.target.value)}
              ></textarea>
            </div>
            <div>
              <Cluster justify="flex-end">
                <div>
                  <button type="submit">Eintragen</button>
                </div>
              </Cluster>
            </div>
          </Stack>
        </form>
      </div>
    </Bordered>
  );
};

export default GuestbookForm;
