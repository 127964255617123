import React from "react";
import { useState, useCallback } from "react";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const GalleryPage = () => {
  const photosQuery = useStaticQuery(graphql`
    query {
      large: allFile(filter: { extension: { regex: "/(jpeg)/" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 900, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      small: allFile(filter: { extension: { regex: "/(jpeg)/" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 640, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);
  const photos = photosQuery.small.edges.map(
    (edge) => edge.node.childImageSharp.fixed
  );
  const photosLarge = photosQuery.large.edges.map(
    (edge) => edge.node.childImageSharp.fixed
  );
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel currentIndex={currentImage} views={photosLarge} />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GalleryPage;
