import React from "react";
import { Box } from "react-every-layout";

const Bordered = ({ children }) => {
  return (
      <Box className="index-logo-border" padding="0">
        <div className="logo-grid">
          <div className="index-logo-border-dot-container">
            <div className="index-logo-border-dot top-left" />
          </div>
          <div />
          <div className="index-logo-border-dot-container">
            <div className="index-logo-border-dot top-right" />
          </div>
          <div />
          {children}
          <div />
          <div className="index-logo-border-dot-container">
            <div className="index-logo-border-dot bottom-left" />
          </div>
          <div />
          <div className="index-logo-border-dot-container">
            <div className="index-logo-border-dot bottom-right" />
          </div>
        </div>
      </Box>
  );
};

export default Bordered;
