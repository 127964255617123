import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Box, Stack, Cluster } from "react-every-layout";
import Bordered from "../components/Bordered";
import { graphql, useStaticQuery } from "gatsby";
import pinstripeLogo from "../img/pinstripe-horizontal.svg";
import GuestbookForm from "../components/GuestbookForm";

const GET_REVIEWS = gql`
  query {
    allGuestbookEntry {
      data {
        author
        text
        _ts
      }
    }
  }
`;

const GuestbookPage = () => {
  const query = useQuery(GET_REVIEWS);

  const content = (() => {
    if (query.loading) {
      return <div>Laden der Gästebucheinträge</div>;
    }
    if (query.error) {
      console.log("Error, fetching", query.error);
      return <div>Fehler beim Laden der Gästebuch-Einträge</div>;
    }
    return (
      <Stack>
        {query.data.allGuestbookEntry.data.map(({ text, author, _ts }, i) => (
          <GuestbookEntry
            className="guestbook-entry"
            key={i}
            text={text}
            author={author}
            date={new Date(_ts / 1000)}
          ></GuestbookEntry>
        ))}
      </Stack>
    );
  })();

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Gästebuch</h1>
            <div
              className="pinstripe-separator"
              style={{ height: "var(--s3)" }}
            />
            {content}
            <div className="pinstripe-separator" />
            <GuestbookForm />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GuestbookPage;

const GuestbookEntry = ({ text, author, date }) => (
  <section className="section">
    {/* <div className="container"> */}
      <h2 className="title">{author} schreibt am {date.toLocaleDateString()}</h2>
      <p>{text}</p>
    {/* </div> */}
  </section>
);
