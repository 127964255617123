import React from "react";
import PropTypes from "prop-types";
import logo from "../img/logo.svg";
import { Link, graphql } from "gatsby";
import { Box, Center } from "react-every-layout";
import Content, { HTMLContent } from "../components/Content";
import Bordered from "../components/Bordered";

import Layout from "../components/Layout";

export const IndexPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <Bordered>
        <div className="index-logo-container">
          <Center andText>
            <img
              src={logo}
              alt="371 Minuten Veredelung Logo"
              style={{ width: "10em", height: "10em" }}
            />
          </Center>
        </div>
      </Bordered>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {title}
                      </h3>
                    </div>
                  </div>
                  <PageContent class="content" content={content} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
