// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-guestbook-js": () => import("./../src/pages/guestbook.js" /* webpackChunkName: "component---src-pages-guestbook-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-partner-page-js": () => import("./../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-services-page-js": () => import("./../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

