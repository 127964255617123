import React from "react";
import { Link } from "gatsby";
import whatsapp from "../img/whatsapp-icon.svg";
import logo from "../img/logo-simple.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link id="start-link" to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="371min.de" style={{ width: "88px" }} />
            </Link>
            {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={this.toggleHamburger}
              >
              <span />
              <span />
              <span />
            <div style={{position: "absolute", right: 42, top: 14}}>Menü</div>
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/about">
                Was ist 371?
              </Link>
              <Link className="navbar-item" to="/services">
                Angebot
              </Link>
              <Link className="navbar-item" to="/gallery">
                Bildergalerie
              </Link>
              <Link className="navbar-item" to="/partner">
                Partner
              </Link>
              <Link className="navbar-item" to="/contact">
                Kontakt
              </Link>
              <Link className="navbar-item" to="/guestbook">
                Gästebuch
              </Link>
              <Link className="navbar-item" to="/imprint">
                Impressum
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="https://wa.me/4917685016149"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={whatsapp} alt="Whatsapp" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
